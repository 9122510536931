import Link from 'next/link'

export function LoginFooter() {
  return (
    <footer className="flex w-full justify-between text-[14px] text-[#989899]">
      <div>
        <span>{'Formación Ninja'}</span>
      </div>
      <div className="flex flex-row gap-2">
        <Link href={'https://formacion.ninja/politica-de-privacidad'}>
          {'Privacidad'}
        </Link>
        <span>{'|'}</span>
        <Link href={'https://formacion.ninja/aviso-legal/aviso-legal'}>
          {'Legal'}
        </Link>
        <span>{'|'}</span>
        <Link href={'https://formacion.ninja/politica-de-cookies'}>
          {'Cookies'}
        </Link>
      </div>
    </footer>
  )
}
