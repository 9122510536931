'use client'
import { LoginFooter } from '@/app/account/components/components/login-footer'
import { Ninja } from 'ui'

export default function AccountContainer({ children }) {
  return (
    <div className="flex h-screen w-screen justify-center bg-[#FAFAFC] pt-9 sm:pt-[132px]">
      <section className="flex w-full max-w-[480px] flex-col items-center p-2">
        <div className="mb-[48px] flex w-full px-2">
          <Ninja style={{ width: 114, height: 45 }} />
        </div>
        <div className="flex w-full">{children}</div>
        <div className="w-full px-1 pt-[32px]">
          <LoginFooter />
        </div>
      </section>
    </div>
  )
}
